<template>
<Page id="tips-tips-games" title="Tipsa matcher">
     <GTable></GTable>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const GTable = () => import("@/components/tips/add/games/GamesTable");

export default {
      name: "TipsGames",
      components: {            
            Page,
            GTable
      },
      metaInfo: {
            title: "Tipsa matcher",
            meta: [{
                  name: "description",
                  content: "Här är alla matcher för turneringen",
            }, ],
      }
};
</script>
